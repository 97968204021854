
import { defineComponent, PropType, reactive, toRef, watch } from 'vue';
import { Message } from '@/utils/models';
export default defineComponent({
  name: 'ChatArea',
  props: {
    messages: {
      type: Object as PropType<Array<Message>>,
      required: true,
    },
    me: String,
    isCalling: Boolean,
  },
  setup(props, { emit }) {
    const messes = toRef(props, 'messages');
    const message = reactive<Message>({
      data: '',
    });
    watch(
      messes,
      (o, n) => {
        const mesContainer = document.getElementById('message') as HTMLElement;
        mesContainer.scrollTop = mesContainer.scrollHeight;
      },
      {
        deep: true,
      },
    );
    const emitMessage = () => {
      emit('send-message', message);
      message.data = '';
      const mesContainer = document.getElementById('message') as HTMLElement;
      mesContainer.scrollTop = mesContainer.scrollHeight;
    };
    return { message, emitMessage };
  },
});
