
import { defineComponent, toRef, PropType } from 'vue';
import { User } from '@/utils/models';
export default defineComponent({
  props: {
    list: Object as PropType<Array<User>>,
    me: String,
    connectedTo: String,
    showHam: Boolean,
    isMuted: Boolean,
  },
  emits: ['call-user', 'close-side', 'toggle-mute', 'close-call'],
  setup(props) {
    const userList = toRef(props, 'list');
    return {
      userList,
    };
  },
});
