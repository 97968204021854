
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, ref, watchEffect } from 'vue';
import { io } from 'socket.io-client';
import ListUsers from '@/components/ListUsers.vue';
import ChatArea from '@/components/ChatArea.vue';
import { Message, User } from '@/utils/models';
import Peer from 'simple-peer';
export default defineComponent({
  name: 'Main',
  components: {
    ListUsers,
    ChatArea,
  },
  setup() {
    const wsUrl =
      process.env.NODE_ENV === 'production'
        ? 'https://soulsam480-node-ws-wrtc.glitch.me'
        : 'http://localhost:3000';
    const config = {
      iceServers: [
        {
          urls: ['turn:13.250.13.83:3478?transport=udp'],
          username: 'YzYNCouZM1mhqhmseWk6',
          credential: 'YzYNCouZM1mhqhmseWk6',
        },
        {
          urls: [
            'stun:stun.l.google.com:19302',
            'stun:stun1.l.google.com:19302',
            'stun:stun2.l.google.com:19302',
          ],
        },
      ],
    };
    let peerConnection = ref<Peer.Instance | null>(null).value;
    const users = ref<Array<User>>([]).value;
    const messages = ref<Array<Message>>([]).value;
    const socket = io(wsUrl, {
      transports: ['websocket'],
    });

    const showLogin = ref(true);
    const isAlreadyCalling = ref(false);
    const showHam = ref(false);
    const userName = ref('');
    const remoteUser = ref('');
    const isConnected = ref(false);
    const stream = ref<MediaStream | null>(null);
    const isMuted = ref(true);
    const modal = ref<{
      isOpen: boolean;
      type: 'call' | 'rejection' | '';
      rejectedBy?: string;
    }>({
      isOpen: false,
      type: '',
      rejectedBy: '',
    });
    function resetModal() {
      modal.value = { isOpen: false, type: '', rejectedBy: '' };
    }
    const callContext = ref<{ name: string; accept: boolean | null }>({
      name: '',
      accept: null,
    });
    const submit = () => {
      socket.emit('add-user', {
        name: userName.value,
      });
      showLogin.value = !showLogin.value;
    };
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then((media) => {
        stream.value = media;
        stream.value.getAudioTracks()[0].enabled = false;
      })
      .catch((err) => console.log(err));

    function toggleAudio() {
      (stream.value as MediaStream).getAudioTracks()[0].enabled = !(stream.value as MediaStream).getAudioTracks()[0]
        .enabled;
      isMuted.value = !isMuted.value;
    }
    function closeCall() {
      peerConnection?.end();
    }
    function killAudio() {
      const audio = document.querySelector('audio') as HTMLAudioElement;
      audio.load();
      if ('srcObject' in audio) {
        audio.srcObject = null;
      } else {
        (audio as HTMLAudioElement).src = '';
        (audio as HTMLAudioElement).removeAttribute('src');
      }
    }
    function createSender(id: string) {
      peerConnection = new Peer({
        initiator: true,
        trickle: false,
        stream: stream.value as MediaStream,
        config,
      });
      peerConnection?.on('signal', (data) => {
        if (data.renegotiate || data.transceiverRequest) return;
        socket.emit('call-user', {
          offer: JSON.stringify(data),
          user: {
            name: userName.value,
            id: socket.id,
          },
          to: id,
        });
      });

      peerConnection?.on('data', (data) => {
        messages.push(JSON.parse(new TextDecoder().decode(data)));
      });
      peerConnection.on('close', () => {
        killAudio();
        isAlreadyCalling.value = false;
        remoteUser.value = '';
        isMuted.value = true;
        (stream.value as MediaStream).getAudioTracks()[0].enabled = false;
        peerConnection = null;
      });
      peerConnection?.on('stream', (stream) => {
        const audio = document.querySelector('audio') as HTMLAudioElement;
        audio.load();
        if ('srcObject' in audio) {
          audio.srcObject = stream;
        } else {
          (audio as any).src = window.URL.createObjectURL(stream); // for older browsers
        }
        const playPromise = audio.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.log(error);
            audio.pause();
          });
        }
      });
    }
    function createReceiver(data: any) {
      const peer = new Peer({
        trickle: false,
        stream: stream.value as MediaStream,
        config,
      });
      peerConnection = peer;
      peerConnection?.signal(data.offer);
      peerConnection?.on('signal', (dat) => {
        if (data.renegotiate || data.transceiverRequest) return;
        socket.emit('make-answer', {
          answer: JSON.stringify(dat),
          to: data.user.id,
          user: {
            name: userName.value,
            id: socket.id,
          },
        });
        remoteUser.value = data.user.name;
        isAlreadyCalling.value = true;
      });
      peerConnection?.on('data', (data) => {
        messages.push(JSON.parse(new TextDecoder().decode(data)));
      });
      peerConnection?.on('connect', () => {
        console.log('connected');
        window.alert('connected!');
      });
      peerConnection.on('close', () => {
        killAudio();
        isAlreadyCalling.value = false;
        remoteUser.value = '';
        isMuted.value = true;
        (stream.value as MediaStream).getAudioTracks()[0].enabled = false;
        peerConnection = null;
      });
      peerConnection?.on('stream', (stream) => {
        const audio = document.querySelector('audio') as HTMLAudioElement;
        audio.load();
        if ('srcObject' in audio) {
          audio.srcObject = stream;
        } else {
          (audio as any).src = window.URL.createObjectURL(stream); // for older browsers
        }
        const playPromise = audio.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.log(error);
            audio.pause();
          });
        }
      });
    }
    const sendMessage = (val: Message) => {
      val.at = new Date().toISOString();
      val.from = userName.value;
      messages.push({ ...val });
      peerConnection?.send(JSON.stringify(val));
    };

    onMounted(() => {
      socket.on('connect', () => {
        isConnected.value = true;
      });

      socket.on('update-user-list', (data: any) => {
        data.users.forEach((el: any) => {
          if (!users.find((eli) => eli.id === el.id)) {
            users.push(el);
          }
        });
      });

      socket.on('remove-user', (data: any) => {
        console.log(`user ${data.socketId} is disconnected.`);
        users.splice(
          users.findIndex((el) => el.id === data.socketId),
          1,
        );
      });

      socket.on('call-made', async (data: any) => {
        callContext.value = { name: data.user.name, accept: null };
        modal.value = { isOpen: true, type: 'call' };
        watchEffect(() => {
          if (callContext.value.accept === true) {
            createReceiver(data);
            modal.value = { isOpen: false, type: '' };
          } else if (callContext.value.accept === false) {
            socket.emit('make-rejection', {
              to: data.user.id,
              user: {
                name: userName.value,
                id: socket.id,
              },
            });
            modal.value = { isOpen: false, type: '' };
          }
        });
      });

      socket.on('rejected', (data: any) => {
        console.log('rejected by ' + data.user.name);
        modal.value = {
          isOpen: true,
          type: 'rejection',
          rejectedBy: data.user.name,
        };
      });

      socket.on('answer-made', async (data: any) => {
        peerConnection?.signal(data.answer);
        peerConnection?.on('connect', async () => {
          console.log('connected');
          window.alert('connected!');
          remoteUser.value = data.user.name;
          isAlreadyCalling.value = true;
        });
      });
    });

    return {
      users,
      sendMessage,
      showLogin,
      submit,
      userName,
      messages,
      isAlreadyCalling,
      remoteUser,
      showHam,
      isConnected,
      createSender,
      isMuted,
      toggleAudio,
      closeCall,
      modal,
      callContext,
      resetModal,
    };
  },
});
